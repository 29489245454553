import React, { useCallback } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import { useRipple } from '@/hooks/useRipple.jsx';

const classNames = {
    sizes: {
        small: 'w-4 h-4',
        normal: 'w-6 h-6',
        large: 'w-8 h-8',
    },
    base: 'flex items-center justify-center aspect-square rounded-md border transition-colors duration-200 ease-in-out cursor-pointer',
};

const checkAnimation = {
    initial: { pathLength: 0, opacity: 0 },
    animate: { pathLength: 1, opacity: 1 },
    exit: { pathLength: 0, opacity: 0 },
    transition: { duration: 0.2 },
};

const CheckboxField = ({ label, name, value, onChange, className, size = 'normal', disabled = false, ...properties }) => {
    const ripple = useRipple();

    const handleChange = useCallback(
        (event) => {
            ripple.create(event);
            if (onChange && !disabled) {
                onChange({ target: { name, value: !value } });
            }
        },
        [onChange, value, disabled, name, ripple],
    );

    return (
        <div className={twMerge('flex items-center gap-2', className)} onClick={handleChange}>
            <motion.div
                className={twMerge(classNames.base, classNames.sizes[size], value ? 'border-[#AAC1B7] bg-[#AAC1B7]/20' : 'border-[#34313266] bg-white', disabled ? 'opacity-50 pointer-events-none' : '')}
                whileTap={{ scale: 0.95 }}
                role='checkbox'
                aria-value={value}
                tabIndex={disabled ? -1 : 0}
                {...properties}
            >
                <AnimatePresence initial={false}>
                    {value && (
                        <svg viewBox='0 0 24 24' className={twMerge('fill-none stroke-[#AAC1B7]', classNames.sizes[size])} strokeWidth='3' strokeLinecap='round' strokeLinejoin='round'>
                            <motion.path d='M20 6L9 17L4 12' {...checkAnimation} />
                        </svg>
                    )}
                </AnimatePresence>
                {ripple.render()}
            </motion.div>

            {label && <label className={`cursor-pointer ${disabled ? 'opacity-50' : ''}`}>{label}</label>}
        </div>
    );
};

export default CheckboxField;
