import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

const motions = {
    label: {
        initial: { top: '50%', translateY: '-50%' },
        animate: (isFocusedOrHasValue) => ({
            top: isFocusedOrHasValue ? 0 : '50%',
            translateY: '-50%',
        }),
        transition: { duration: 0.2 },
    },
};

const PincodeField = ({ label, error, onChange, value: externalValue, name, ...properties }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [internalValue, setInternalValue] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        if (externalValue != null) {
            setInternalValue(externalValue.replace(/\D/g, ''));
        }
    }, [externalValue]);

    const formatPinCode = (code) => {
        if (code == null) return '';
        const digits = code.replace(/\D/g, '').slice(0, 6);
        return digits.length > 3 ? `${digits.slice(0, 3)}-${digits.slice(3)}` : digits;
    };

    const handleChange = (event) => {
        const newValue = event.target.value.replace(/\D/g, '').slice(0, 6);
        setInternalValue(newValue);

        if (onChange) {
            const newEvent = {
                ...event,
                target: {
                    ...event.target,
                    value: newValue,
                    name: name,
                },
            };
            onChange(newEvent);
        }
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const borderColor = error ? 'border-red-500' : 'border-[#34313266]';
    const labelColor = error ? 'text-red-500' : 'text-[#212121]';

    const displayValue = formatPinCode(internalValue);
    const hasValue = internalValue.length > 0;

    return (
        <div className={twMerge('relative w-full rounded-lg border bg-white', borderColor)}>
            <input ref={inputRef} type='text' inputMode='numeric' autoComplete='one-time-code' className='text-gray-900 h-14 w-full bg-transparent px-4' onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange} value={displayValue} maxLength={7} name={name} {...properties} />
            <motion.label className={twMerge('pointer-events-none absolute left-1 bg-[inherit] px-3 rounded-full', labelColor)} initial={motions.label.initial} animate={motions.label.animate(isFocused || hasValue)} transition={motions.label.transition}>
                {label}
            </motion.label>
        </div>
    );
};

export default PincodeField;
