import React, { useEffect, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

const motions = {
    label: {
        initial: { top: '50%', translateY: '-50%' },
        animate: (isFocusedOrHasValue) => ({
            top: isFocusedOrHasValue ? 0 : '50%',
            translateY: '-50%',
        }),
        transition: { duration: 0.2 },
    },
};

const InputField = ({ label, error, value, onChange, ...properties }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [hasValue, setHasValue] = useState(value !== '' && value !== undefined);

    const isFocusedOrHasValue = useMemo(() => isFocused || hasValue, [isFocused, hasValue]);

    useEffect(() => {
        setHasValue(value !== '' && value !== undefined);
    }, [value]);

    function handleFocus() {
        setIsFocused(true);
    }

    function handleBlur() {
        setIsFocused(false);
    }

    function handleChange(event) {
        if (onChange) {
            onChange(event);
        }
        setHasValue(event.target.value !== '');
    }

    const borderColor = error ? 'border-red-500' : 'border-[#34313266]';
    const labelColor = error ? 'text-red-500' : 'text-[#212121]';

    return (
        <div className={twMerge('relative w-full rounded-lg border bg-white', borderColor)}>
            <input className='text-gray-900 h-14 w-full bg-transparent px-4' onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange} value={value} {...properties} />
            <motion.label className={twMerge('pointer-events-none absolute left-1 bg-[inherit] rounded-t-full px-3', labelColor)} initial={motions.label.initial} animate={motions.label.animate(isFocusedOrHasValue)} transition={motions.label.transition}>
                {label}
            </motion.label>
        </div>
    );
};

export default InputField;
