import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

const motions = {
    label: {
        initial: { top: '50%', translateY: '-50%' },
        animate: (isFocusedOrHasValue) => ({
            top: isFocusedOrHasValue ? 0 : '50%',
            translateY: '-50%',
        }),
        transition: { duration: 0.2 },
    },
    dropdown: {
        initial: { opacity: 0, y: -10 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -10 },
        transition: { duration: 0.2 },
    },
};

const SelectField = ({ label, options, value, onChange, error, multiple = false, name, ...properties }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const selectRef = useRef(null);
    const optionsRef = useRef([]);

    const selectedOptions = useMemo(() => {
        if (multiple) {
            return options.filter((option) => value && value.includes(option?.value));
        }
        return value != null ? [options.find((option) => option?.value === value)] : [];
    }, [options, value, multiple]);

    const hasValue = selectedOptions.length > 0;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setIsOpen(false);
                setIsFocused(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleToggle = () => {
        setIsOpen(!isOpen);
        setIsFocused(!isOpen);
        if (!isOpen) {
            setFocusedIndex(0);
        }
    };

    const handleSelect = (option) => {
        let newValue;
        if (multiple) {
            newValue = value && value.includes(option?.value) ? value.filter((v) => v !== option?.value) : [...(value || []), option?.value];
        } else {
            newValue = option?.value === value ? null : option?.value;
            setIsOpen(false);
            setIsFocused(false);
        }

        onChange({
            target: {
                name,
                value: newValue,
            },
        });
    };

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => {
        if (!isOpen) {
            setIsFocused(false);
        }
    };

    const handleKeyDown = (e) => {
        if (!isOpen) {
            if (e.key === 'Enter' || e.key === ' ' || e.key === 'ArrowDown') {
                e.preventDefault();
                setIsOpen(true);
                setIsFocused(true);
                setFocusedIndex(0);
            }
        } else {
            switch (e.key) {
                case 'ArrowDown':
                    e.preventDefault();
                    setFocusedIndex((prevIndex) => (prevIndex + 1) % options.length);
                    break;
                case 'ArrowUp':
                    e.preventDefault();
                    setFocusedIndex((prevIndex) => (prevIndex - 1 + options.length) % options.length);
                    break;
                case 'Enter':
                case ' ':
                    e.preventDefault();
                    if (focusedIndex !== -1) {
                        handleSelect(options[focusedIndex]);
                    }
                    break;
                case 'Escape':
                    e.preventDefault();
                    setIsOpen(false);
                    setIsFocused(false);
                    break;
                default:
                    break;
            }
        }
    };

    useEffect(() => {
        if (isOpen && focusedIndex !== -1) {
            optionsRef.current[focusedIndex]?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [focusedIndex, isOpen]);

    const borderColor = error ? 'border-red-500' : 'border-[#34313266]';
    const labelColor = error ? 'text-red-500' : 'text-[#212121]';

    const renderChips = () => {
        return selectedOptions.map(
            (option) =>
                option && (
                    <div key={option.value} className='m-1 flex items-center rounded-full border border-[#21212133] bg-white px-2 py-1'>
                        <span className='text-xs text-[#212121]'>{option.label}</span>
                        <button
                            type='button'
                            onClick={(e) => {
                                e.stopPropagation();
                                handleSelect(option);
                            }}
                            className='ml-1 focus:outline-none'
                        >
                            <svg xmlns='http://www.w3.org/2000/svg' className='size-4 text-[#212121] ' viewBox='0 0 20 20' fill='currentColor'>
                                <path fillRule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clipRule='evenodd' />
                            </svg>
                        </button>
                    </div>
                ),
        );
    };

    return (
        <div className={twMerge('relative w-full rounded-lg border bg-white', borderColor)} ref={selectRef}>
            <div className='flex min-h-[56px] w-full cursor-pointer flex-wrap items-center bg-transparent px-4 py-2' onClick={handleToggle} onFocus={handleFocus} onBlur={handleBlur} onKeyDown={handleKeyDown} tabIndex={0} {...properties}>
                {renderChips()}
            </div>

            <motion.label className={twMerge('pointer-events-none absolute left-1 bg-[inherit] px-3 rounded-t-full', labelColor)} initial={motions.label.initial} animate={motions.label.animate(isFocused || hasValue)} transition={motions.label.transition}>
                {label}
            </motion.label>

            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4'>
                <motion.svg className='size-5 text-[#212121]' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' animate={{ rotate: isOpen ? 180 : 0 }} transition={{ duration: 0.2 }}>
                    <path fillRule='evenodd' d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z' clipRule='evenodd' />
                </motion.svg>
            </div>

            <AnimatePresence>
                {isOpen && (
                    <motion.ul className='absolute z-10 mt-2 w-full overflow-auto rounded-md border border-[#34313266] bg-white shadow-lg' style={{ top: 'calc(100% + 8px)' }} {...motions.dropdown}>
                        {options.map((option, index) => (
                            <li
                                key={option?.value}
                                ref={(el) => (optionsRef.current[index] = el)}
                                className={`flex h-[56px] cursor-pointer items-center justify-between px-4 ${multiple ? (value && value.includes(option?.value) ? 'bg-[#F3F3F3]' : '') : option?.value === value ? 'bg-[#F3F3F3]' : ''} ${
                                    index === focusedIndex ? 'bg-[#E8F0FE]' : ''
                                } hover:bg-[#E6E6E6] active:bg-[#D9D9D9]`}
                                onClick={() => handleSelect(option)}
                            >
                                <span>{option?.label}</span>
                                {(multiple ? value && value.includes(option?.value) : value === option?.value) && <span className='size-2 rounded-full bg-[#212121]'></span>}
                            </li>
                        ))}
                    </motion.ul>
                )}
            </AnimatePresence>
        </div>
    );
};

export default SelectField;
