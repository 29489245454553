import React, { useEffect, useRef } from 'react';
import { Turnstile } from '@marsidev/react-turnstile';

const CaptchaField = ({ onChange, name, siteKey, ...props }) => {
    const turnstileRef = useRef(null);

    useEffect(() => {
        return () => {
            if (turnstileRef.current) {
                turnstileRef.current.reset();
            }
        };
    }, []);

    const handleSuccess = (token) => {
        onChange({
            target: {
                name,
                value: token,
            },
        });
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Turnstile
                ref={turnstileRef}
                siteKey={siteKey}
                onSuccess={handleSuccess}
                options={{
                    appearance: 'execute',
                    size: 'flexible',
                    theme: 'light',
                }}
                {...props}
            />
        </div>
    );
};

export default React.memo(CaptchaField);
