import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import InputField from '@/components/v2/fields/InputField.jsx';
import Error from '@/components/v2/Error.jsx';
import CheckboxField from '@/components/v2/fields/CheckboxField.jsx';
import { ToggleButtonGroupField } from '@/components/v2/fields/ToggleButtonGroupField.jsx';
import Pincode from '@/components/v2/fields/PincodeField.jsx';
import SelectField from '@/components/v2/fields/SelectField.jsx';
import Phone from '@/components/v2/fields/PhoneField.jsx';
import CaptchaField from '@/components/v2/fields/CaptchaField.jsx';

// Helper functions voor geneste waarden (ongewijzigd)
const getNestedValue = (obj, path) => {
    return path.split('.').reduce((current, key) => current?.[key], obj);
};

const setNestedValue = (obj, path, value) => {
    if (typeof path === 'string') {
        const keys = path.split('.');
        let current = obj;

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (i === keys.length - 1) {
                current[key] = value;
            } else {
                if (current[key] === undefined || current[key] === null || typeof current[key] !== 'object') {
                    current[key] = {};
                }
                current = current[key];
            }
        }
    } else if (typeof path === 'number' || typeof path === 'symbol') {
        obj[path] = value;
    }

    return obj;
};

const FormContext = createContext({});

// Hoofd Form component (ongewijzigd)
const Form = ({ form, children, className, onSubmit, prefix = '', ...props }) => {
    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit?.(event);
    };

    const contextValue = useMemo(
        () => ({
            form,
            prefix,
        }),
        [form, prefix],
    );

    return (
        <FormContext.Provider value={contextValue}>
            <form className={twMerge('flex flex-col gap-5', className)} onSubmit={handleSubmit} {...props}>
                {children}

                <input type={'submit'} hidden />
            </form>
        </FormContext.Provider>
    );
};

const Field = ({ name, type = 'text', onChange, className, required, ...props }) => {
    const { form, prefix } = useContext(FormContext);

    const fullPath = useMemo(() => {
        if (!name && !prefix) return '';
        if (!name) return prefix;
        if (!prefix) return name;
        return `${prefix}.${name}`;
    }, [name, prefix]);

    const value = fullPath ? getNestedValue(form.data, fullPath) : form.data;
    const displayValue = value !== undefined && value !== null ? value : '';
    const error = form.errors && fullPath ? getNestedValue(form.errors, fullPath) : null;

    const handleChange = useCallback(
        (e) => {
            if (onChange) {
                const result = onChange(e);
                if (result && typeof result === 'object') {
                    form.setData((prevData) => {
                        let updatedData = { ...prevData };
                        Object.entries(result).forEach(([key, value]) => {
                            if (typeof value === 'object' && value !== null) {
                                const existingValue = getNestedValue(updatedData, key) || {};
                                updatedData = setNestedValue(updatedData, key, { ...existingValue, ...value });
                            } else {
                                updatedData = setNestedValue(updatedData, key, value);
                            }
                        });
                        return updatedData;
                    });
                    return;
                }
            }

            const newValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
            form.setData((prevData) => {
                if (fullPath) {
                    return setNestedValue({ ...prevData }, fullPath, newValue);
                } else {
                    return newValue;
                }
            });
        },
        [form, fullPath, onChange],
    );

    const renderInput = () => {
        const inputMap = {
            'checkbox': CheckboxField,
            'toggle-button-group': ToggleButtonGroupField,
            'pincode': Pincode,
            'select': SelectField,
            'phone': Phone,
            'captcha': CaptchaField,
        };

        const Component = inputMap[type] || InputField;

        const commonProps = {
            id: fullPath || undefined,
            name: fullPath || undefined,
            onChange: handleChange,
            value: displayValue,
            error: error,
            ...(Component === InputField ? { type, value: displayValue } : {}),
            ...props,
        };

        return <Component {...commonProps} />;
    };

    return (
        <div className={twMerge('flex flex-col gap-2', className)}>
            {renderInput()}
            <Error error={error} />
        </div>
    );
};

Form.Field = Field;

export default Form;
